/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useMutation } from '@apollo/client';
import { useUserInfo } from 'hooks';
import { TOGGLE_TRACKING_URL_MONITORING } from 'pages/Merchants/FintelCheck/FintelCheckPublisherSearch/graphql/mutations/toggleTrackingUrlMonitoring';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { paginator } from 'utils';
import { useDebounce } from 'utils/useDebounce';

import { Permission } from '../../../../../entities';
import { UPDATE_FCHECK_SETTINGS } from '../graphql/mutations';
import { GET_FCHECK_SETTINGS, LIST_PUBLISHERS } from '../graphql/queries';
import { GET_CUSTOM_PUBLISHER_LIST_OPTIONS } from '../graphql/queries/getCustomPublisherListOptions';

export const usePublisherSearch = (permissionsCodeList: string[] = []) => {
  // ===== States =====
  const navigate = useNavigate();
  const { hookWhoAmI } = useUserInfo();

  // Toggle Monitoring
  const [trackingUrlMonitoringToggle, setTrackingUrlMonitoringToggle] = useState(false);
  const [monitoringErrors, setMonitoringErrors] = useState<string>('');
  const urlCheckTypeOptions = { manual: 'manual', relevant: 'relevant', both: 'both' };

  // Filters
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 800);
  const [category, setCategory] = useState<SelectOption | undefined>();
  const [categoriesList, setCategoriesList] = useState<SelectOption[]>([{ label: 'All Categories', value: '' }]);
  const [location, setLocation] = useState<SelectOption | undefined>();
  const [locationsList, setLocationsList] = useState<SelectOption[]>([{ label: 'All Countries', value: '' }]);

  // Modal info
  const [selectedPublisherList, setSelectedPublisherList] = useState<any[]>([]);
  const [disableButton, setDisableButton] = useState(true);

  // Table info
  const [tableData, setTableData] = useState<any[]>([]);
  const [publisherList, setPublisherList] = useState<any[]>([]);
  const [publisherListTemp, setPublisherListTemp] = useState<any[]>([]);
  const [isHeaderChecked, setIsHeaderChecked] = useState<boolean>();
  const [sortColumn, setSortColumn] = useState<TableSortColumn>({ column: '', direction: 'asc' });
  const [recordsPerPage, setRecordsPerPage] = useState<SelectOption>({ label: '10', value: '10' });

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [recordsCount, setRecordsCount] = useState(0);

  // Queries & Mutations
  const [getFintelCheckSettings, { loading: getSettingsLoading }] = useLazyQuery(GET_FCHECK_SETTINGS);
  const [getCustomPublisherListOptions, { loading: getOptionsLoading }] = useLazyQuery(
    GET_CUSTOM_PUBLISHER_LIST_OPTIONS
  );
  const [getPublishers, { loading: getPublisherLoading }] = useLazyQuery(LIST_PUBLISHERS);
  const [updateFintelCheckSettings] = useMutation(UPDATE_FCHECK_SETTINGS);
  const [toggleTrackingUrlMonitoring, { loading: toggleTrackingUrlMonitoringLoading }] =
    useMutation(TOGGLE_TRACKING_URL_MONITORING);

  // ===== Setters =====

  const setSearchHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const setCategoryHandler = (value: SelectOption): void => {
    setCategory(value);
  };

  const setLocationHandler = (value: SelectOption): void => {
    setLocation(value);
  };

  const setRecordsHandler = (value: SelectOption): void => {
    setRecordsPerPage(value);
  };

  const setCurrentPageHandler = (page: number): void => {
    setCurrentPage(page);
  };

  // ===== Handle Fetch & Mutate Data =====

  const getPublisherList = async (): Promise<void> => {
    const [{ data: fcheckSettings }, { data: filterOptions }, { data: publisherFullList }] = await Promise.all([
      getFintelCheckSettings({
        variables: { input: { merchantId: Number(hookWhoAmI?.companyId) } },
        fetchPolicy: 'no-cache',
      }),
      getCustomPublisherListOptions({
        variables: { input: { programId: hookWhoAmI?.programId } },
        fetchPolicy: 'no-cache',
      }),
      getPublishers({ variables: { input: { programId: hookWhoAmI?.programId } }, fetchPolicy: 'no-cache' }),
    ]);

    if (filterOptions?.getCustomPublisherListOptions) {
      setLocationsList([...locationsList, ...filterOptions.getCustomPublisherListOptions.countries]);
      setCategoriesList([...categoriesList, ...filterOptions.getCustomPublisherListOptions.categories]);
    }

    if (publisherFullList?.listPublishersForMerchants?.publishers) {
      const publishersToSet = publisherFullList.listPublishersForMerchants.publishers.map(
        (item: any, index: number) => ({
          checked: !!fcheckSettings?.getFintelCheckSettings?.settings?.publisherSettings.includes(item.id) || false,
          index,
          id: item.id,
          company: {
            name: item.companyName,
            website: item.companyUrl,
          },
          country: item.location,
          productCategories: item.productCategories,
        })
      );
      setSelectedPublisherList(publishersToSet.filter((item: any) => item.checked === true));
      setTableData(paginator(publishersToSet, Number(recordsPerPage.value), 1));
      setPublisherList(publishersToSet);
      setRecordsCount(publisherFullList.listPublishersForMerchants.publishers.length);
      setTotalPages(
        Math.ceil(publisherFullList.listPublishersForMerchants.publishers.length / Number(recordsPerPage.value))
      );
      setTrackingUrlMonitoringToggle(
        fcheckSettings?.getFintelCheckSettings?.settings?.urlCheckType === urlCheckTypeOptions.both ||
        fcheckSettings?.getFintelCheckSettings?.settings?.urlCheckType === urlCheckTypeOptions.relevant
      );
    } else {
      setRecordsCount(0);
      setTotalPages(0);
    }
    setCurrentPage(1);
  };

  const updateCustomList = async (): Promise<void> => {
    const { errors } = await updateFintelCheckSettings({
      variables: {
        input: {
          merchantId: Number(hookWhoAmI?.companyId),
          publisherSettings: selectedPublisherList.map((item) => item.id),
        },
      },
    });
    if (!errors) {
      navigate(-1);
    }
  };

  // ===== Handle Table Changes =====

  const handleFilter = (): void => {
    const pubListToSet = publisherList
      // Filter on Dropdowns
      .filter(
        (item) =>
          (location === undefined || location?.value === '' || item.country === location?.value) &&
          (category === undefined || category?.value === '' || item.productCategories?.includes(category?.value))
      )
      // Filter on Search Value
      .filter(
        (item) =>
          debouncedSearch === '' ||
          debouncedSearch === undefined ||
          item.company.name?.toLowerCase().includes(debouncedSearch) ||
          item.company.website?.toLowerCase().includes(debouncedSearch) ||
          item.id.includes(debouncedSearch)
      );
    setPublisherListTemp(pubListToSet);
    setTableData(paginator(pubListToSet, Number(recordsPerPage.value), 1));
    setTotalPages(Math.ceil(pubListToSet.length / Number(recordsPerPage.value)));
    setRecordsCount(pubListToSet.length);
    setCurrentPage(1);
  };

  const resetFilters = (): void => {
    setSearch('');
    setCategory(undefined);
    setLocation(undefined);
  };

  const handlePageChange = (page: number): void => {
    const paginatedData = paginator(
      debouncedSearch ? publisherListTemp : publisherList,
      Number(recordsPerPage.value),
      page
    );
    setTableData(paginatedData);
    setCurrentPage(page);
  };

  const handleSort = (dataField: string, direction: any): void => {
    const nextDirection: number = direction === 'asc' ? -1 : 1;
    const compare = (a: any, b: any) => {
      let compareCondition = false;
      switch (dataField) {
        case 'id':
          compareCondition = Number(a.id) >= Number(b.id);
          break;
        case 'company':
          compareCondition = a.company.name.toLowerCase() >= b.company.name.toLowerCase();
          break;
        default:
          compareCondition = a[dataField] === b[dataField] ? Number(a.id) >= Number(b.id) : a[dataField] > b[dataField];
          break;
      }
      return compareCondition ? nextDirection : nextDirection * -1;
    };
    const copyArray =
      debouncedSearch ||
        (location !== undefined && location?.value !== '') ||
        (category !== undefined && category?.value !== '')
        ? [...publisherListTemp]
        : [...publisherList];
    const sortedArray = copyArray.sort(compare);
    setPublisherListTemp(sortedArray);
    setTableData(paginator(sortedArray, Number(recordsPerPage.value), 1));
    setSortColumn({ column: dataField, direction: sortColumn.direction === 'desc' ? 'asc' : 'desc' });
    setCurrentPage(1);
  };

  const handleRecordsChange = (): void => {
    setCurrentPage(1);
    const pubList =
      publisherListTemp.length > 0 && publisherListTemp.length !== publisherList.length
        ? [...publisherListTemp]
        : [...publisherList];

    setTableData(paginator(pubList, Number(recordsPerPage.value), 1));
    setTotalPages(Math.ceil(pubList.length / Number(recordsPerPage.value)));
  };

  // ===== Handle Selecting Pubs =====

  const setIsHeaderHandler = (): void => {
    setIsHeaderChecked(false);
  };

  const setHeaderCheckHandler = (): void => {
    setIsHeaderChecked(!isHeaderChecked);
    const pubListToSet =
      debouncedSearch ||
        (location !== undefined && location?.value !== '') ||
        (category !== undefined && category?.value !== '')
        ? [...publisherListTemp].map((item) => ({ ...item, checked: !isHeaderChecked }))
        : [...publisherList].map((item) => ({ ...item, checked: !isHeaderChecked }));
    setPublisherList(pubListToSet);
    setTableData(paginator(pubListToSet, Number(recordsPerPage.value), 1));
    setSelectedPublisherList(pubListToSet.filter((item) => item.checked));
  };

  const setPublisherListHandler = (value: Record<string, any>): void => {
    if (selectedPublisherList.some((publisher) => publisher.id === value.id)) {
      const test = selectedPublisherList.findIndex((publisher) => publisher.id === value.id);
      selectedPublisherList.splice(test, 1);
      setSelectedPublisherList(selectedPublisherList);
    } else if (value.checked) {
      setSelectedPublisherList([...selectedPublisherList, value]);
    }
  };

  const handleRowClick = (row: any): void => {
    const [isReadOnly] = Permission.readOnlyPermissionsList(permissionsCodeList);

    if (isReadOnly) return;

    const copyPublisherList = [...publisherList];
    const copyPublisherListTemp = [...publisherListTemp];
    const index = copyPublisherList.findIndex((item) => item.id === row.id);
    if (index !== -1) copyPublisherList[index].checked = !copyPublisherList[index].checked;
    setPublisherList(copyPublisherList);
    setPublisherListTemp(copyPublisherListTemp);
    const pubListToSet =
      debouncedSearch ||
        (location !== undefined && location?.value !== '') ||
        (category !== undefined && category?.value !== '')
        ? copyPublisherListTemp
        : copyPublisherList;
    setTableData(paginator(pubListToSet, Number(recordsPerPage.value), currentPage));
    setPublisherListHandler({ ...row, checked: !row.checked });
  };

  const removeFromList = (item: any): void => {
    setSelectedPublisherList(selectedPublisherList.filter((publisher) => publisher !== item));
    setTableData(
      tableData.map((publisher) => {
        if (item.index === publisher.index) {
          return {
            ...publisher,
            checked: false,
          };
        }
        return {
          ...publisher,
        };
      })
    );
  };

  const trackingUrlMonitoringToggleHandler = async (): Promise<void> => {
    const { data, errors } = await toggleTrackingUrlMonitoring({
      variables: {
        input: {
          merchantId: Number(hookWhoAmI?.companyId),
          enabled: trackingUrlMonitoringToggle,
        },
      },
    });
    if (errors) {
      setMonitoringErrors(errors[0].message);
      return;
    }
    if (data?.toggleTrackingUrlMonitoring) {
      setTrackingUrlMonitoringToggle(
        data.toggleTrackingUrlMonitoring.settings.urlCheckType === urlCheckTypeOptions.both ||
        data.toggleTrackingUrlMonitoring.settings.urlCheckType === urlCheckTypeOptions.relevant
      );
    }
  };

  useEffect(() => {
    trackingUrlMonitoringToggleHandler();
  }, [trackingUrlMonitoringToggle]);

  const resetPublisherList = (): void => {
    setSelectedPublisherList([]);
  };

  // ===== useEffects =====

  useEffect(() => {
    getPublisherList();
  }, []);

  useEffect(() => {
    handleRecordsChange();
  }, [recordsPerPage]);

  useEffect(() => {
    handleFilter();
  }, [location, category, debouncedSearch]);

  useEffect(() => {
    setDisableButton(!(selectedPublisherList.length > 0));
  }, [selectedPublisherList]);

  // ===== exports =====
  return {
    hookLoading: getOptionsLoading || getSettingsLoading || getPublisherLoading || toggleTrackingUrlMonitoringLoading,

    hookNavigate: navigate,
    hookSearch: search,
    hookSetSearch: setSearchHandler,

    hookCategory: category,
    hookCategoryOptions: categoriesList,
    hookSetCategory: setCategoryHandler,

    hookLocation: location,
    hookLocationOptions: locationsList,
    hookSetLocation: setLocationHandler,

    hookHandleFilters: handleFilter,
    hookResetFilters: resetFilters,

    hookRecords: recordsPerPage,
    hookTotalRecords: recordsCount,
    hookSetRecords: setRecordsHandler,

    hookDisableButton: disableButton,
    hookData: tableData,
    hookSetData: setTableData,
    hookHeader: isHeaderChecked,
    hookSetHeader: setHeaderCheckHandler,
    hookSetIsHeader: setIsHeaderHandler,

    hookCurrentPage: currentPage,
    hookTotalPages: totalPages,
    hookSetPage: setCurrentPageHandler,
    hookOnPageChange: handlePageChange,

    hookHandleSort: handleSort,
    hookSortColumn: sortColumn,

    hookOnRowClick: handleRowClick,

    hookUpdateList: setPublisherListHandler,
    hookPublisherList: selectedPublisherList,
    hookRemovePublisher: removeFromList,
    hookResetPublisherList: resetPublisherList,
    hookUpdateCustomList: updateCustomList,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),

    trackingUrlMonitoringToggle,
    setTrackingUrlMonitoringToggle,
    monitoringErrors,
  };
};
