import { IconProp } from '@fortawesome/fontawesome-svg-core';
import * as ICONS from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, ReactElement, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSideMenu } from '../../../../hooks/useSideMenu';
import { ISideMenuItem } from '../../../../types';
import { SIDE_MENU_LEVELS } from '../../SideMenuItem.constants';
import { IconAsLink } from '../../SideMenuItem.styles';

import { MenuItemContainer, MenuItem, IconContainer } from './SideMenuItemAsHref.styles';

interface ISideMenuItemAsHrefProps {
  menuItem: ISideMenuItem;
}

const SideMenuItemAsHref: FC<ISideMenuItemAsHrefProps> = ({ menuItem }): ReactElement | null => {
  const { isCollapsed, isSideMenuItemOpened, toggleSideMenuItem } = useSideMenu();
  const navigate = useNavigate();

  if (!menuItem.href) return null;

  const handleMenuItemClick = (e: MouseEvent<HTMLDivElement | HTMLAnchorElement>): void => {
    e.preventDefault();
    toggleSideMenuItem(menuItem.id, SIDE_MENU_LEVELS.TOP_LEVEL, !!menuItem.subSections);
    setTimeout(() => navigate(menuItem.href as string), 100);
  };

  return (
    <MenuItemContainer
      isActive={isSideMenuItemOpened(SIDE_MENU_LEVELS.TOP_LEVEL, menuItem.id)}
      onClick={(e) => handleMenuItemClick(e)}
      key={menuItem.label}
      id={menuItem.label.replace(/ /g, '')}
    >
      <MenuItem
        isCollapsed={isCollapsed}
        to={menuItem.href}
        onClick={(e) => handleMenuItemClick(e)}
        key={menuItem.label}
      >
        <FontAwesomeIcon icon={ICONS[menuItem.icon as keyof IconProp]} />
        <p>{menuItem.label}</p>
      </MenuItem>
      {menuItem.link && !isCollapsed && (
        <IconContainer>
          <IconAsLink isCollapsed={isCollapsed} to={menuItem.href} target="_blank">
            <FontAwesomeIcon icon={ICONS[menuItem.link as keyof IconProp]} />
          </IconAsLink>
        </IconContainer>
      )}
    </MenuItemContainer>
  );
};

export default SideMenuItemAsHref;
